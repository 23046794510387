export const initContactsForm = () => {
	const form = document.getElementById("js-contacts-form") as HTMLFormElement | null;

	if (form) {
		form.addEventListener("submit", event => {
			event.preventDefault();
			event.stopPropagation();

			if (!form.checkValidity()) {
			}

			form.classList.add("was-validated");

			console.dir(event.currentTarget);
		});
	}
};
