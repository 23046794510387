/**
 *
 */
export default function detectAvifWebp(document: Document): void {
	requestAnimationFrame(() => {
		const avif = new Image();
		const webp = new Image();

		avif.src =
			"data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0" +
			"YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAA" +
			"AeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAx" +
			"Q29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAA" +
			"xhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAK" +
			"CBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
		avif.onload = () => {
			document.body.classList.add("has-avif");
		};
		avif.onerror = () => {
			document.body.classList.add("no-avif");
		};

		webp.src = "data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==";
		webp.onload = () => {
			document.body.classList.add("has-webp");
		};
		webp.onerror = () => {
			document.body.classList.add("no-webp");
		};
	});
}
