const hint = `<div class="hint-wrapper js-hint">
    <div class="hint">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" fill="none" viewBox="0 0 20 30">
            <path fill="#455A64"
                  d="M14.47 29.938h-4.187c-1.302 0-2.606-.565-3.771-1.63l-.012-.012-2.024-1.799a6.709 6.709 0 0 1-1.768-2.561L.63 18.202c-.267-.74.07-1.547.783-1.88a2.345 2.345 0 0 1 3.028 1.046l1.602 3.656c.213-.322.331-.705.331-1.1V6.22a.516.516 0 1 1 1.032 0v13.705a3.031 3.031 0 0 1-1.22 2.426.516.516 0 0 1-.779-.208L3.51 17.811c-.293-.562-1.05-.836-1.665-.553a.48.48 0 0 0-.245.594l2.073 5.724a5.593 5.593 0 0 0 1.489 2.153l2.028 1.803.019.017c.987.9 2.021 1.357 3.074 1.357h4.187a4.137 4.137 0 0 0 4.09-3.448c.126-.74.19-1.284.19-1.615v-6.796a1.032 1.032 0 0 0-2.063 0 .516.516 0 0 1-1.031 0v-1.031a1.032 1.032 0 0 0-2.063 0 .516.516 0 1 1-1.031 0V15.5a1.032 1.032 0 0 0-2.063 0 .516.516 0 1 1-1.03 0V6.219c0-.57-.464-1.032-1.032-1.032a.516.516 0 0 1 0-1.03c1.137 0 2.062.924 2.062 2.062v7.496a2.065 2.065 0 0 1 2.8.723 2.054 2.054 0 0 1 1.325-.485c.899 0 1.665.578 1.947 1.381a2.06 2.06 0 0 1 3.21 1.713v6.796c0 .394-.066.979-.203 1.787a5.167 5.167 0 0 1-5.108 4.308ZM5.52 3.817a.518.518 0 0 1-.366-.15l-.729-.73a.516.516 0 0 1 .73-.729l.729.73a.516.516 0 0 1-.364.879Zm2.917-1.209a.516.516 0 0 1-.516-.515v-1.03a.516.516 0 1 1 1.032 0v1.03a.516.516 0 0 1-.516.516Zm2.917 1.209a.515.515 0 0 1-.365-.88l.73-.729a.516.516 0 0 1 .729.73l-.73.728a.516.516 0 0 1-.364.151Zm2.24 2.916h-1.032a.516.516 0 0 1 0-1.03h1.031a.516.516 0 0 1 0 1.03Zm-9.282 0H3.28a.516.516 0 1 1 0-1.03h1.03a.516.516 0 0 1 0 1.03Z"/>
            <path fill="#EFB35A"
                  d="M14.47 29.938h-4.187c-1.302 0-2.606-.565-3.771-1.63l-.012-.012-2.024-1.799a6.709 6.709 0 0 1-1.768-2.561L.63 18.202c-.267-.74.07-1.547.783-1.88a2.345 2.345 0 0 1 3.028 1.046l1.602 3.656c.213-.322.331-.705.331-1.1V6.22a.516.516 0 1 1 1.032 0v13.705a3.031 3.031 0 0 1-1.22 2.426.516.516 0 0 1-.779-.208L3.51 17.811c-.293-.562-1.05-.836-1.665-.553a.48.48 0 0 0-.245.594l2.073 5.724a5.593 5.593 0 0 0 1.489 2.153l2.028 1.803.019.017c.987.9 2.021 1.357 3.074 1.357h4.187a4.137 4.137 0 0 0 4.09-3.448c.126-.74.19-1.284.19-1.615v-6.796a1.032 1.032 0 0 0-2.063 0 .516.516 0 0 1-1.031 0v-1.031a1.032 1.032 0 0 0-2.063 0 .516.516 0 1 1-1.031 0V15.5a1.032 1.032 0 0 0-2.063 0 .516.516 0 1 1-1.03 0V6.219c0-.57-.464-1.032-1.032-1.032a.516.516 0 0 1 0-1.03c1.137 0 2.062.924 2.062 2.062v7.496a2.065 2.065 0 0 1 2.8.723 2.054 2.054 0 0 1 1.325-.485c.899 0 1.665.578 1.947 1.381a2.06 2.06 0 0 1 3.21 1.713v6.796c0 .394-.066.979-.203 1.787a5.167 5.167 0 0 1-5.108 4.308ZM5.52 3.817a.518.518 0 0 1-.366-.15l-.729-.73a.516.516 0 0 1 .73-.729l.729.73a.516.516 0 0 1-.364.879Zm2.917-1.209a.516.516 0 0 1-.516-.515v-1.03a.516.516 0 1 1 1.032 0v1.03a.516.516 0 0 1-.516.516Zm2.917 1.209a.515.515 0 0 1-.365-.88l.73-.729a.516.516 0 0 1 .729.73l-.73.728a.516.516 0 0 1-.364.151Zm2.24 2.916h-1.032a.516.516 0 0 1 0-1.03h1.031a.516.516 0 0 1 0 1.03Zm-9.282 0H3.28a.516.516 0 1 1 0-1.03h1.03a.516.516 0 0 1 0 1.03Z"/>
        </svg>
    </div>
</div>`;

const createIndicators = (slides: NodeListOf<HTMLElement>, slider: Element) => {
	const indicators = document.createElement("div");

	indicators.classList.add("slider-dots");

	slides.forEach((slide: HTMLElement, idx) => {
		const dot = document.createElement("span");
		dot.classList.add("slider-dots-dot");
		dot.setAttribute("data-slide-indicator", "");

		if (idx === 0) {
			dot.classList.add("isCurrent");
		}

		dot.addEventListener("click", () => {
			slider.scrollTo({
				left: slide.offsetLeft,
			});
		});

		indicators.appendChild(dot);
	});

	return indicators;
};

const createSlider = (
	slider: HTMLElement,
	slides: NodeListOf<HTMLElement>,
	isMobile: MediaQueryList,
) => {
	const indicators = createIndicators(slides, slider);
	const dots = indicators.querySelectorAll("[data-slide-indicator]");

	slider.parentNode.appendChild(indicators);

	const observer = new IntersectionObserver(
		(entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					const idx = [...slides].indexOf(entry.target as HTMLElement);
					dots.forEach(dot => dot.classList.remove("isCurrent"));
					dots[idx].classList.add("isCurrent");
				}
			});
		},
		{
			root: slider,
			threshold: 1,
			rootMargin: "0px 30px", // as paddings in catalog-page. see .slide
		},
	);

	slides.forEach(slide => observer.observe(slide));

	isMobile.addEventListener("change", event => {
		if (!event.matches) {
			observer.disconnect();
		} else {
			slides.forEach(slide => observer.observe(slide));
		}
	});
};

export const initSlider = () => {
	const slides: NodeListOf<HTMLElement> = document.querySelectorAll(".js-slider");

	if (slides && slides.length > 0) {
		const isMobile = window.matchMedia("(max-width: 767px");

		if (!isMobile.matches) {
			return;
		}

		let isHintInserted = false;

		slides.forEach((slider, index) => {
			const slides: NodeListOf<HTMLElement> = slider.querySelectorAll("[data-slide]");

			if (slides.length > 1) {
				if (!isHintInserted) {
					(slider.parentNode as HTMLElement).insertAdjacentHTML("beforebegin", hint);
					slider.addEventListener("scroll", () => {
						setTimeout(() => {
							document.querySelector(".js-hint").classList.add("isHidden");
						}, 2000);

						slider.onscroll = undefined;
					});
					isHintInserted = true;
				}

				createSlider(slider, slides, isMobile);
			}
		});
	}
};
