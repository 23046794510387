import appRun from "./on-load";

// Лоудеш, Аксиос обычно нужны везде
//window._ = require('lodash');
//window.axios = require('axios');
//window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

document.addEventListener("DOMContentLoaded", () => {
	appRun(document, window);
});
