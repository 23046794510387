import { Dropdown, Modal } from "bootstrap";

export const initCitySelector = () => {
	const citySelector = document.querySelector(".js-city-selector");

	if (citySelector) {
		const chooseDifferentCityBtn = document.querySelector(".js-choose-city-btn");
		const confirmBtn = document.querySelector(".js-city-confirm-btn");
		const cityBtn = document.querySelectorAll(".js-choose-city");

		const dropdown = new Dropdown(citySelector, {
			autoClose: "outside",
		});

		const cityModal = new Modal(document.getElementById("citiesModal"), {
			backdrop: true,
		});

		citySelector.addEventListener("click", () => {
			dropdown.toggle();
		});

		chooseDifferentCityBtn.addEventListener("click", () => {
			cityModal.show();
		});

		confirmBtn.addEventListener("click", () => {
			dropdown.hide();
		});

		cityBtn.forEach(btn => {
			btn.addEventListener("click", event => {
				const city = (event.target as HTMLElement).dataset.city;
				alert(city);
				cityModal.hide();
				dropdown.hide();
			});
		});
	}
};
