export const initCart = () => {
	const form: HTMLFormElement = document.querySelector(".js-cart-form");

	if (form) {
		const deliveryType = document.querySelectorAll(".js-delivery-type");
		const [addressForm, addressInfo] = document.querySelectorAll("[data-address]");

		form.addEventListener("submit", event => {
			event.preventDefault();
			event.stopPropagation();

			if (!form.checkValidity()) {
				form.classList.add("was-validated");
			} else {
				console.log("Form is valid");
			}
		});

		deliveryType.forEach((deliveryRadioNode: HTMLInputElement) => {
			deliveryRadioNode.addEventListener("change", event => {
				const node = event.currentTarget as HTMLInputElement;

				if (node.value === "pickup") {
					addressForm.classList.add("isHidden");
					addressForm
						.querySelectorAll("input")
						.forEach(input => input.removeAttribute("required"));
					addressInfo.classList.remove("isHidden");
				} else {
					addressForm.classList.remove("isHidden");
					addressInfo.classList.add("isHidden");
					addressForm
						.querySelectorAll("input")
						.forEach(input => input.setAttribute("required", "required"));
				}
			});
		});
	}
};
