export const initQuantity = () => {
	const quantityNodes = document.querySelectorAll(".js-quantity");

	quantityNodes.forEach((quantityNode: HTMLElement) => {
		const buttons = quantityNode.querySelectorAll("[data-btn]");
		const output: HTMLInputElement = quantityNode.querySelector("[data-quanitity='output']");
		const [decreaseBtn, increaseBtn] = buttons;
		let timeoutId: number;

		output.addEventListener("input", event => {
			const field = event.currentTarget as HTMLInputElement;
			const isValid = field.form.reportValidity();
			clearTimeout(timeoutId);

			if (!isValid) {
				increaseBtn.setAttribute("disabled", "disabled");

				timeoutId = setTimeout(() => {
					field.value = field.max;
					decreaseBtn.removeAttribute("disabled");
				}, 3000);
			} else {
				increaseBtn.removeAttribute("disabled");
			}
		});

		buttons.forEach((btn: HTMLButtonElement) => {
			btn.addEventListener("click", event => {
				const maxAmount = Number(output.max);
				const initialAmount = Number(output.value);
				const currentTarget = event.currentTarget as HTMLButtonElement;
				const type = currentTarget.dataset.btn;
				const amount = type === "increase" ? initialAmount + 1 : initialAmount - 1;

				if (amount >= 1 && amount <= maxAmount) {
					output.value = amount.toString();
				}

				if (amount === 1) {
					decreaseBtn.setAttribute("disabled", "disabled");
				} else {
					decreaseBtn.removeAttribute("disabled");
				}

				if (amount === maxAmount) {
					increaseBtn.setAttribute("disabled", "disabled");
				} else {
					increaseBtn.removeAttribute("disabled");
				}
			});
		});
	});
};
