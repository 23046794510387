import "bootstrap/js/src/util";
import { Collapse, Popover, Dropdown, Modal } from "bootstrap";

import detectAvifWebp from "./modules/avif-webp-detect";
import { initRelativeTime } from "./modules/relative-time";
import { initContactsForm } from "./modules/contacts-form";
import { initSlider } from "./modules/slider";
import { initCitySelector } from "./modules/citySelector";
import { initQuantity } from "./modules/quantity";
import { initCart } from "./modules/cart";

export default function appRun(document: Document, window: Window & typeof globalThis) {
	// Убираем маркер безъяваскриптовости
	window.document.body.classList.remove("no-js");

	// Определение поддержки AVIF и WebP
	detectAvifWebp(document);

	// Инициализация тегов времени
	initRelativeTime(document);

	// Поповеры из Бутстрапа
	document
		.querySelectorAll('[data-bs-toggle="popover"]')
		.forEach((popover: HTMLElement) => new Popover(popover));

	document.querySelectorAll(".collapse").forEach(collapseEl => {
		new Collapse(collapseEl, {
			toggle: false,
		});
	});

	document
		.querySelectorAll('[data-bs-toggle="dropdown"]')
		.forEach((dropdownToggleEl: HTMLElement) => new Dropdown(dropdownToggleEl));

	initContactsForm();
	initSlider();
	initCitySelector();
	initQuantity();
	initCart();
}
